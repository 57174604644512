import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import useInView from "react-cool-inview";

const BlogItemFlat = ({ title }) => {
    const { observe, inView } = useInView();
    return (
        <div className="c-blog-listing__item {{class}}" ref={observe}>
            <Link className={inView ? "c-blog-listing__item-link is-inview" : "c-blog-listing__item-link"} to="/flat/insights-article">
                <div className="c-blog-listing__item-media-container">
                    <div className="c-blog-listing__item-media-wrapper">
                        <img src="https://picsum.photos/320/180" alt="" className="c-blog-listing__item-media" loading="lazy"/>
                    </div>
                    <div className="c-blog-listing__item-category">Category name</div>
                </div>
                <h3 className="c-blog-listing__item-title">{title}</h3>
                <p className="c-blog-listing__item-summary">All base UI elements are made using Nested Symbols and shared styles that are logically connected with one another.</p>
                <time className="c-blog-listing__item-date" dateTime="2021-06-12">12 June 2021</time>
            </Link>
        </div>
    )
}

class BlogLatestFlat extends React.Component {
    render() {
        const { heading } = this.props
        return (
            <div className="c-blog-listing c-blog-listing--featured">
                <div className="container">
                    <div className="row">
                        <div className="c-blog-listing__header">
                            <h2 className="c-blog-listing__header-title">{heading}</h2>
                            <Link to="/flat/insights" className="c-blog-listing__header-link c-btn c-btn--primary">Discover more</Link>
                        </div>
                    </div>
                    <div className="row">
                        <BlogItemFlat title="How to save planet earth in the 2020s" />
                        <BlogItemFlat title="10 tips for fundraising for science in UK universities" />
                        <BlogItemFlat title="Reinforced glass, whilst shock absorbent and transparent" />
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogLatestFlat

BlogLatestFlat.propTypes = {
    heading: PropTypes.string
}