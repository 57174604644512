import * as React from "react"
import { LayoutFlat } from "../../components/flat/layout"
import MastheadFlat from "../../components/flat/masthead"
import ContentFlat from "../../components/flat/content"
import BlogLatestFlat from "../../components/flat/blog-latest"

const ServiceFlat = () => (
    <LayoutFlat>
        <MastheadFlat cssModifier="c-masthead--media" />
        <ContentFlat />
        <BlogLatestFlat heading="Related" />
    </LayoutFlat>
)

export default ServiceFlat
